import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

const Modal = ({ isOpen, onClose, title, content, imgSrc, showDesignerTeamButton }) => {
  const { t } = useTranslation();
  const navigate = useNavigate(); 

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Cleanup to enable scrolling
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle} onClick={onClose}>
      <div style={modalStyle} onClick={(e) => e.stopPropagation()}>
        <img src={imgSrc} alt={title} style={modalImageStyle} />
        <h3 style={modalTitleStyle}>{title}</h3>
        <p style={modalContentStyle}>{content}</p>
        <div style={modalFooterStyle}>
          {showDesignerTeamButton && (
            <button
              onClick={() => navigate("/designer-team")}
              style={designerTeamButtonStyle}
              // className="designer-team-button"
            >
              {t("Designer Team")}
            </button>
          )}
          <button
          onClick={onClose} 
          style={closeButtonStyle}>
            {t("close")}
          </button>
        </div>
      </div>
    </div>
  );
};


const modalFooterStyle = {
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between", // Push buttons to opposite corners
  alignItems: "center", // Align buttons vertically
  width: "100%", // Ensure the container spans full width
};


const designerTeamButtonStyle = {
  background: "#FFD700",
  border: "none",
  borderRadius: "5px",
  padding: "10px 15px",
  cursor: "pointer",
  color: "black",
  fontSize: "16px",
};


const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker overlay
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000, // Ensure the overlay is on top of everything
};

const modalStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "12px",
  width: "500px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
  textAlign: "center",
  zIndex: 1001,
};

const modalImageStyle = {
  width: "100%",
  height: "auto",
  borderRadius: "12px",
  marginBottom: "15px",
};

const modalTitleStyle = {
  color: "#d4af37",
  fontSize: "24px",
  margin: "10px 0",
};

const modalContentStyle = {
  color: "#333",
  fontSize: "16px",
  margin: "10px 0",
};

const closeButtonStyle = {
  marginTop: "10px",
  background: "#FFD700",
  border: "none",
  borderRadius: "5px",
  padding: "10px 15px",
  cursor: "pointer",
  color: "black",
  fontSize: "16px",
};

export const Services = (props) => {
  const { t } = useTranslation();

  return (
    <div
      id="services"
      className="text-center"
      style={{
        background:
          "radial-gradient(circle 878px at 52.2% 62.3%, rgba(20, 156, 73, 1) 0%, rgba(8, 31, 26, 1) 90%)",
        padding: "50px 0",
      }}
    >
      <div className="container">
        <div className="section-title">
          <h2 style={{ color: "#FFD700" }}>{t("ourServicesTitle")}</h2>{" "}
          {/* Use translation */}
          <p style={{ color: "#d4af37" }}>
            {t("ourServicesDescription")} {/* Use translation */}
          </p>
        </div>

        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <ServiceCard key={`${d.name}-${i}`} service={d} />
              ))
            : t("loading")}{" "}
          {/* Use translation for loading text */}
        </div>
      </div>
    </div>
  );
};


// const ServiceCard = ({ service }) => {
//   const { imgSrc, name, text } = service; // `name` and `text` are translation keys
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const { t } = useTranslation(); // Translation hook

//   return (
//     <div className="col-md-4">
//       <img
//         src={imgSrc}
//         alt={t(name)} // Use translation for the name
//         style={{
//           width: "150px",
//           height: "auto",
//           objectFit: "cover",
//           borderRadius: "50%",
//         }}
//       />
//       <div className="service-desc">
//         <h3 style={{ color: "#d4af37" }}>{t(name)}</h3> {/* Use translation */}
//         <p style={{ color: "#d4af37" }}>
//           {t(text).length > 49 ? `${t(text).slice(0, 49)}...` : t(text)}{" "}
//           {/* Use translation */}
//         </p>
//         {t(text).length > 49 && (
//           <button
//             onClick={() => setIsModalOpen(true)}
//             style={{
//               color: "#FFD700",
//               background: "none",
//               border: "none",
//               cursor: "pointer",
//               padding: 0,
//               textDecoration: "underline",
//             }}
//           >
//             {t("readMore")} {/* Use translation */}
//           </button>
//         )}
//       </div>

//       <Modal
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         title={t(name)} // Pass translated name as title
//         content={t(text)} // Pass translated text as content
//         imgSrc={imgSrc} // Pass image source to Modal
//         showDesignerTeamButton={name === "designAndEngineering"} 
//       />
//     </div>
//   );
// };


const ServiceCard = ({ service }) => {
  const { imgSrc, name, text } = service; // `name` and `text` are translation keys
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation(); // Translation hook
  const navigate = useNavigate(); // Initialize navigate

  const handleReadMore = () => {
    if (name === "designAndEngineering") {
      // Navigate to the designer team page when the "Read More" is clicked on Design and Engineering card
      navigate("/designer-team");
    } else {
      // Otherwise, open the modal
      setIsModalOpen(true);
    }
  };

  // Check if the service is "Design and Engineering"
  const showDesignerTeamButton = name === "designAndEngineering";

  return (
    <div className="col-md-4">
      <img
        src={imgSrc}
        alt={t(name)} // Use translation for the name
        style={{
          width: "150px",
          height: "auto",
          objectFit: "cover",
          borderRadius: "50%",
        }}
      />
      <div className="service-desc">
        <h3 style={{ color: "#d4af37" }}>{t(name)}</h3> {/* Use translation */}
        <p style={{ color: "#d4af37" }}>
          {t(text).length > 49 ? `${t(text).slice(0, 49)}...` : t(text)}{" "}
          {/* Use translation */}
        </p>
        {t(text).length > 49 && (
          <button
            onClick={handleReadMore}
            style={{
              color: "#FFD700",
              background: "none",
              border: "none",
              cursor: "pointer",
              padding: 0,
              textDecoration: "underline",
            }}
          >
            {t("readMore")} {/* Use translation */}
          </button>
        )}
      </div>

      {/* Modal for non-"Design and Engineering" services */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t(name)} // Pass translated name as title
        content={t(text)} // Pass translated text as content
        imgSrc={imgSrc} // Pass image source to Modal
        showDesignerTeamButton={showDesignerTeamButton} // Pass the conditionally calculated value
      />
    </div>
  );
};

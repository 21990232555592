// import React from "react";

// const Logistic = ({ data }) => {
//   if (!data) {
//     return <p>No logistic data available.</p>;
//   }

//   const { name, role, phone, email, imgSrc } = data;

//   const containerStyle = {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: "30px",
//     borderRadius: "10px",
//     backgroundColor: "#f5f5f5", // Light background for modern feel
//     boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
//     color: "#333", // Dark text for readability
//     margin: "20px auto",
//     width: "90%",
//     maxWidth: "800px",
//   };

//   const headerStyle = {
//     textAlign: "center",
//     marginBottom: "20px",
//   };

//   const titleStyle = {
//     fontSize: "32px",
//     fontWeight: "600",
//     color: "#4CAF50", // Fresh green accent for the title
//     marginBottom: "10px",
//   };

//   const descriptionStyle = {
//     fontSize: "16px",
//     color: "#777", // Subtle gray for description text
//     lineHeight: "1.6",
//     fontStyle: "italic",
//   };

//   const detailsContainerStyle = {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     width: "100%",
//     gap: "20px",
//     marginTop: "20px",
//   };

//   const textSectionStyle = {
//     flex: 1,
//     display: "flex",
//     flexDirection: "column",
//     gap: "15px",
//   };

//   const nameStyle = {
//     fontSize: "28px",
//     fontWeight: "700",
//     color: "#333", // Darker text for the name
//   };

//   const roleStyle = {
//     fontSize: "20px",
//     color: "#555", // Subtle gray for the role
//     fontStyle: "italic",
//   };

//   const contactStyle = {
//     fontSize: "16px",
//     color: "#777",
//     lineHeight: "1.6",
//   };

//   const imageStyle = {
//     width: "180px",
//     height: "200px",
//     borderRadius: "50%",
//     objectFit: "cover",
//     boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
//     border: "3px solid #4CAF50", // Green border around the image
//   };

//   const lineStyle = {
//     height: "1px",
//     width: "60%",
//     backgroundColor: "#4CAF50",
//     margin: "15px 0",
//   };

//   return (
//     <div style={containerStyle}>
//       {/* Header Section */}
//       <div style={headerStyle}>
//         <div style={titleStyle}>Logistics Manager</div>
//         <p style={descriptionStyle}>
//           A Logistics Manager ensures smooth operations, optimizing resources and coordinating supply chains.
//         </p>
//       </div>

//       {/* Details Section */}
//       <div style={detailsContainerStyle}>
//         {/* Text Section */}
//         <div style={textSectionStyle}>
//           <div style={nameStyle}>{name}</div>
//           <div style={roleStyle}>{role}</div>
//           <div style={lineStyle}></div> {/* Divider */}
//           <div style={contactStyle}>
//             <p>📞 Phone: {phone}</p>
//             <p>📧 Email: {email}</p>
//           </div>
//         </div>

//         {/* Image Section */}
//         <img src={imgSrc} alt={`${name}'s photo`} style={imageStyle} />
//       </div>
//     </div>
//   );
// };

// export default Logistic;





// import React from "react";
// import { t } from 'i18next'; // Assuming i18next is used for translation

// const Logistic = ({ data }) => {
//   if (!data) {
//     return <p>No logistic data available.</p>;
//   }

//   const { name, role, phone, email, imgSrc } = data;

//   const containerStyle = {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: "30px",
//     borderRadius: "10px",
//     backgroundColor: "#f5f5f5", // Light background for modern feel
//     boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
//     color: "#333", // Dark text for readability
//     margin: "20px auto",
//     width: "90%",
//     maxWidth: "800px",
//   };

//   const headerStyle = {
//     textAlign: "center",
//     marginBottom: "20px",
//   };

//   const titleStyle = {
//     fontSize: "32px",
//     fontWeight: "600",
//     color: "#4CAF50", // Fresh green accent for the title
//     marginBottom: "10px",
//   };

//   const descriptionStyle = {
//     fontSize: "16px",
//     color: "#777", // Subtle gray for description text
//     lineHeight: "1.6",
//     fontStyle: "italic",
//   };

//   const detailsContainerStyle = {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     width: "100%",
//     gap: "20px",
//     marginTop: "20px",
//   };

//   const textSectionStyle = {
//     flex: 1,
//     display: "flex",
//     flexDirection: "column",
//     gap: "15px",
//   };

//   const nameStyle = {
//     fontSize: "28px",
//     fontWeight: "700",
//     color: "#333", // Darker text for the name
//   };

//   const roleStyle = {
//     fontSize: "20px",
//     color: "#555", // Subtle gray for the role
//     fontStyle: "italic",
//   };

//   const contactStyle = {
//     fontSize: "16px",
//     color: "#777",
//     lineHeight: "1.6",
//   };

//   const imageStyle = {
//     width: "180px",
//     height: "200px",
//     borderRadius: "50%",
//     objectFit: "cover",
//     boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
//     border: "3px solid #4CAF50", // Green border around the image
//   };

//   const lineStyle = {
//     height: "1px",
//     width: "60%",
//     backgroundColor: "#4CAF50",
//     margin: "15px 0",
//   };

//   return (
//     <div style={containerStyle}>
//       {/* Header Section */}
//       <div style={headerStyle}>
//         <div style={titleStyle}>
//           {t("logisticsManagerTitle")}
//         </div>
//         <p style={descriptionStyle}>
//           {t("logisticsManagerDescription" )}
//         </p>
//       </div>

//       {/* Details Section */}
//       <div style={detailsContainerStyle}>
//         {/* Text Section */}
//         <div style={textSectionStyle}>
//           <h3 className="logistic-name" style={nameStyle}>
//             {t(`teamMemberAlt.${name}`, { defaultValue: name })}
//           </h3>
//           <p className="logistic-role" style={roleStyle}>
//             {t(`teamMemberJob.${role}`, { defaultValue: role })}
//           </p>
//           <div style={lineStyle}></div> {/* Divider */}
//           <p className="logistic-phoneNumber" style={contactStyle}>
//             {t("teamMemberPhone", { phone: phone })}
//           </p>
//           {email && (
//             <p className="logistic-email" style={contactStyle}>
//               {t("teamMemberEmail", { email: email })}
//             </p>
//           )}
//         </div>

//         {/* Image Section */}
//         <img src={imgSrc} alt={`${name}'s photo`} style={imageStyle} />
//       </div>
//     </div>
//   );
// };

// export default Logistic;






import React from "react";
import { useTranslation } from "react-i18next";

const Logistic = ({ data }) => {
  const { t } = useTranslation(); // Hook for translations

  if (!data) {
    return <p>{t("noLogisticData")}</p>;
  }

  const { name, role, phone, email, imgSrc } = data;

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
    borderRadius: "10px",
    backgroundColor: "#f5f5f5", // Light background for modern feel
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
    color: "#333", // Dark text for readability
    margin: "20px auto",
    width: "90%",
    maxWidth: "800px",
  };

  const headerStyle = {
    textAlign: "center",
    marginBottom: "20px",
  };

  const titleStyle = {
    fontSize: "32px",
    fontWeight: "600",
    color: "#4CAF50", // Fresh green accent for the title
    marginBottom: "10px",
  };

  const descriptionStyle = {
    fontSize: "16px",
    color: "#777", // Subtle gray for description text
    lineHeight: "1.6",
    fontStyle: "italic",
  };

  const detailsContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "20px",
    marginTop: "20px",
  };

  const textSectionStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  };

  const nameStyle = {
    fontSize: "28px",
    fontWeight: "700",
    color: "#333", // Darker text for the name
  };

  const roleStyle = {
    fontSize: "20px",
    color: "#555", // Subtle gray for the role
    fontStyle: "italic",
  };

  const contactStyle = {
    fontSize: "16px",
    color: "#777",
    lineHeight: "1.6",
  };

  const imageStyle = {
    width: "180px",
    height: "200px",
    borderRadius: "50%",
    objectFit: "cover",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
    border: "3px solid #4CAF50", // Green border around the image
  };

  const lineStyle = {
    height: "1px",
    width: "60%",
    backgroundColor: "#4CAF50",
    margin: "15px 0",
  };

  return (
    <div style={containerStyle}>
      {/* Header Section */}
      <div style={headerStyle}>
        <div style={titleStyle}>
          {t("logisticsManagerTitle")}
        </div>
        <p style={descriptionStyle}>
          {t("logisticsManagerDescription")}
        </p>
      </div>

      {/* Details Section */}
      <div style={detailsContainerStyle}>
        {/* Text Section */}
        <div style={textSectionStyle}>
          <h3 className="logistic-name" style={nameStyle}>
            {t(`teamMemberAlt.${name}`)}
          </h3>
          <p className="logistic-role" style={roleStyle}>
            {t(`teamMemberJob.${role}`)}
          </p>
          <div style={lineStyle}></div> {/* Divider */}
          <p className="logistic-phoneNumber" style={contactStyle}>
            {t("teamMemberPhone", { phone })}
          </p>
          {email && (
            <p className="logistic-email" style={contactStyle}>
              {t("teamMemberEmail", { email })}
            </p>
          )}
        </div>

        {/* Image Section */}
        <img src={imgSrc} alt={`${name}'s photo`} style={imageStyle} />
      </div>
    </div>
  );
};

export default Logistic;

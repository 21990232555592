import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";  
import { Navigation } from "./components/navigation";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { ImportExport } from "./components/ImportExport";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import Logistic from "./components/logistic"; // Updated component name
import OilPage from "./pages/oil"; 
import GasPage from "./pages/gas"; 
import PetrochemicalPage from "./pages/petrochemical"; 
import MedicinePage from "./pages/medicine"; 
import DesigningPage from "./pages/designing"; 
import ConstructionPage from "./pages/construction"; 
import CounselingPage from "./pages/counseling"; 
import InvestmentPage from "./pages/investment"; 
import { DesignerTeam } from "./pages/designerTeam";
import JsonData from "./data/data.json";  
import "./App.css";
// import Designing from "./pages/designing";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <Routes>
        <Route 
          path="/" 
          element={(
            <div>
              <Navigation />
              <Header data={landingPageData.Header} />
              <Features data={landingPageData.Features} />
              <About data={landingPageData.About} />
              <ImportExport data={landingPageData.ImportExport} />
              <Services data={landingPageData.Services} />
              {/* <Designing data={landingPageData.DesignerTeam}/> */}
              <Team data={landingPageData.Team} />
              {<Logistic data={landingPageData.logistics?.[0]} />}
              <Contact data={landingPageData.Contact} />
            </div>
          )}
        />
        <Route path="/oil" element={<OilPage />} />
        <Route path="/gas" element={<GasPage />} />
        <Route path="/petrochemical" element={<PetrochemicalPage />} />
        <Route path="/medicine" element={<MedicinePage />} />
        <Route path="/designing" element={<DesigningPage />} />
        <Route path="/construction" element={<ConstructionPage />} />
        <Route path="/counseling" element={<CounselingPage />} />
        <Route path="/investment" element={<InvestmentPage />} />
        {/* <Route path="/designer-team" element={<DesignerTeam />} /> */}
        <Route 
  path="/designer-team" 
  element={landingPageData.DesignerTeam ? <DesignerTeam data={landingPageData.DesignerTeam} /> : <p>Loading...</p>} 
/>

      </Routes>
    </Router>
  );
};

export default App;


import React from "react";
import { useTranslation } from "react-i18next";
import './designerteam.css';

export const DesignerTeam = ({ data = [] }) => {
  const { t } = useTranslation();

  if (!data.length) {
    return <p className="loading">{t("loading")}</p>; // Fallback while data loads
  }

  return (
    <div className="designer-team">
      <h2 className="designer-team-title">{t("designerTeamTitle")}</h2>
      <p className="designer-team-description">{t("designerTeamDescription")}</p>

      <div className="designer-team-container">
        {data.map((member, index) => (
          <div key={index} className="designer-card">
            <img 
              src={member.imgSrc} 
              alt={t(`teamMemberAlt.${member.name}`, { defaultValue: member.name })} 
              className="designer-image" 
            />
            <div className="designer-info">
              <h3 className="designer-name">{t(`teamMemberAlt.${member.name}`, { defaultValue: member.name })}</h3>
              <p className="designer-role">
                {t(`teamMemberJob.${member.role}`, { defaultValue: member.role })}
              </p>
              <p className="designer-phoneNumber">
                {t("teamMemberPhone", { phone: member.phone })}
              </p>
              {member.email && (
                <p className="designer-email">
                  {t("teamMemberEmail", { email: member.email })}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>

      <button className="designer-learn-more">
        {t("learnMore", { defaultValue: "Learn More" })}
      </button>
    </div>
  );
};
